<template>
  <div
    class="modal fade"
    id="addKategori"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <form @submit.prevent="onSubmit">
          <div class="card-modal">
            <div class="modal-header">
              <div class="modal-title text-center">
                {{ labelModal }}
              </div>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="form-group row align-items-center">
                <label class="col-sm-3 mb-0">Kategori</label>
                <div class="col-sm-9">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Masukkan Kategori"
                    v-model="formData.nama"
                    required
                  />
                </div>
              </div>
              <div class="form-group row align-items-center">
                <label class="col-sm-3 mb-0">Status</label>
                <div class="col-sm-9">
                  <Select2
                    v-model="formData.status"
                    :options="statusCompany"
                    placeholder="Status Kategori"
                    @change="myChangeEvent($event)"
                    @select="mySelectEvent($event)"
                    required
                  />
                </div>
              </div>
              <div class="text-right">
                <button type="submit" class="btn btn-save" :disabled="isSubmit">
                  Simpan
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <section class="section">
        <div class="d-flex mb-4 align-items-center">
          <div class="mr-auto">
            <h4 class="title">Kategori di Project</h4>
          </div>
          <!-- <form class="search mr-3" @submit.prevent="doSearch">
            <input
              type="text"
              id="searchbox"
              v-model="search"
              @keyup="searchCheck"
              placeholder="Cari..."
              name="search"
            />
            <button type="submit"><i class="fa fa-search"></i></button>
          </form> -->
          <button class="btn-add" @click="showModal">+ Tambah</button>
        </div>
        <div class="card-table">
          <div class="table-responsive">
            <table
              class="table table-bordered table-striped table-hover"
              id="TableKategori"
            >
              <thead>
                <tr>
                  <th style="width: 85%">Kategori</th>
                  <th style="width: 10%">Status</th>
                  <th class="text-center" style="width: 8%">Aksi</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(value, index) in listData" :key="index">
                  <td>{{ value.nama }}</td>
                  <td>
                    <div v-if="value.status == 'aktif'" class="green-bedge">
                      Aktif
                    </div>
                    <div class="red-bedge" v-else>Nonaktif</div>
                  </td>
                  <td class="text-center">
                    <svg
                      class="detail-icon"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Edit"
                      v-on:click="detail(value.id, value.nama, value.status)"
                      width="20"
                      height="20"
                      viewBox="0 0 31 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.5 25.8301H27.125"
                        stroke="#898989"
                        stroke-width="2.33333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M21.3125 4.52324C21.8264 4.00938 22.5233 3.7207 23.25 3.7207C23.6098 3.7207 23.9661 3.79158 24.2986 3.92928C24.631 4.06698 24.9331 4.26881 25.1875 4.52324C25.4419 4.77768 25.6438 5.07974 25.7815 5.41217C25.9192 5.74461 25.99 6.10091 25.99 6.46074C25.99 6.82057 25.9192 7.17687 25.7815 7.50931C25.6438 7.84175 25.4419 8.14381 25.1875 8.39824L9.04167 24.5441L3.875 25.8357L5.16667 20.6691L21.3125 4.52324Z"
                        stroke="#898989"
                        stroke-width="2.33333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../components/Sidebar.vue";
import NavbarDashboard from "../../../components/Navbar.vue";
import Select2 from "vue3-select2-component";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";

import {
  post_ProjectKategoriSave,
  get_ProjectKategoriList,
} from "../../../actions/project/kategori";
import { cksClient, showAlert } from "../../../helper";

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    // TableLite
  },
  data() {
    return {
      statusCompany: [
        { id: "aktif", text: "Aktif" },
        { id: "tidak", text: "Nonaktif" },
      ], // or [{id: key, text: value}, {id: key, text: value}]
      listData: [],
      account: cksClient().get("_account"),
      search: "",
      dataTable: "",
      isSearch: false,
      formData: {
        id: "",
        id_company: cksClient().get("_account").id_company,
        nama: "",
        status: "",
      },
      labelModal: "Tambah Kategori",
      isSubmit: false,
    };
  },
  created() {
    this.getList();
  },

  beforeUnmount() {
    if (this.dataTable) {
      this.dataTable.destroy();
      this.dataTable = "";
    }
  },
  methods: {
    myChangeEvent(val) {
      console.log(val);
    },
    mySelectEvent({ id, text }) {
      console.log({
        id,
        text,
      });
    },
    tooltip() {
      $('[data-toggle="tooltip"]').tooltip();
    },
    detail(id, nama, status) {
      this.labelModal = "Edit Kategori";
      this.formData.id = id;
      this.formData.nama = nama;
      this.formData.status = status;
      this.isSubmit = false;
      $("#addKategori").modal("show");
      $('[data-toggle="tooltip"]').tooltip("hide");
    },
    searchCheck() {
      if (!this.search && this.isSearch) {
        this.isSearch = false;
        this.getList();
      }
    },
    doSearch() {
      if (this.search || this.isSearch) {
        this.isSearch = true;
        this.getList();
      }
      if (!this.search && this.isSearch) {
        this.isSearch = false;
      }
    },
    getList: async function () {
      await get_ProjectKategoriList(
        {
          id_company: this.account.id_company,
          search: this.search,
        },
        (res) => {
          this.listData = [];
          this.listData = res.list;
          if (this.dataTable) {
            this.dataTable.destroy();
          }
          setTimeout(() => {
            this.tooltip();
            this.dataTable = $("#TableKategori").DataTable({
              info: false,
            });
          }, 500);
        },
        (err) => {
          console.log(err);
        }
      );
    },
    showModal: function () {
      this.labelModal = "Tambah Kategori";
      this.formData.id = "";
      this.formData.nama = "";
      this.formData.status = "aktif";
      this.isSubmit = false;
      $("#addKategori").modal("show");
    },
    postData() {
      this.isSubmit = true;
      post_ProjectKategoriSave(
        this.formData,
        (res) => {
          this.isSubmit = false;
          $("#addKategori").modal("hide");
          var msg =
            res.response_code == 201
              ? "Data kategori berhasil ditambahkan"
              : "Data kategori berhasil diperbarui";
          this.getList();
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: msg,
            showCancelButton: false,
          });
        },
        () => {
          $("#addKategori").modal("hide");
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Terjadi Kesalahan, silakan ulangi kembali",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },
    onSubmit: async function () {
      showAlert(this.$swal, {
        title: "PERHATIAN!",
        msg: this.formData.id
          ? "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?"
          : "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Lanjutkan",
        onSubmit: () => {
          this.postData();
        },
      });
    },
  },
  mounted() {
    this.tooltip();
  },
};
</script>

<style scoped>
button.btn-add {
  border: none !important;
}
.card-table {
  margin-top: 0px !important;
}
</style>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>
