import { API } from "../../../constans/Url"
import { apiClient } from "../../../helper"


const get_ProjectKategoriList = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.PROJECT.KATEGORI.LIST, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_ProjectKategoriSave = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.PROJECT.KATEGORI.SAVE, params).then(res => {
    if (res.status === 201 || res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

export {
  get_ProjectKategoriList,
  post_ProjectKategoriSave
}